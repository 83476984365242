function onCartAddComplete() {
   try {
      const cartDrawerToggle = document.querySelector('[data-testid=cart-drawer-open]');
      if (cartDrawerToggle) {
         const click = new Event('click', { bubbles: true });
         cartDrawerToggle.dispatchEvent(click);
      } else {
         console.warn('Cart drawer toggle element not found');
      }
   } catch (error) {
      console.error('Error opening cart drawer:', error);
   }
}

listenGlobal('cart.addcomplete', onCartAddComplete);
